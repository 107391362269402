import { Loader } from './Loader';

export const Loading = (props) => {
    return (
        <div className="loading-container" style={{justifyContent: 'center', alignItems: 'center'}}>
            <div>
                <Loader size={64} />
                <h5>Loading...</h5>
            </div>
        </div>
    );
}