/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import { useEffect, useState } from "react";
import { Col, Container, Form, Row, Button, InputGroup } from "react-bootstrap";
import GoogleMapReact from 'google-map-react';
import { css, keyframes } from '@emotion/react';
// import { Router, useParams } from '@reach/router';
import { BASE_URL } from './Constants';
import { Loader } from './Loader';

const COLOR = '#000';
var timer;

export const Embed = (props) => {
    const [account, setAccount] = useState({ theme_color: '#efefef' });
    const [locations, setLocations] = useState([]);
    const [map, setMap] = useState();
    const [userLat, setUserLat] = useState();
    const [userLng, setUserLng] = useState();
    const [selectedLocation, setSelectedLocation] = useState();
    const [queryString, setQueryString] = useState('');
    // const params = useParams();
    const [accountId, setAccountId] = useState('');
    const [requestInProgress, setRequestInProgress] = useState(false);

    // Pagination
    const [currentPage, setCurrentPage] = useState();
    const [lastPage, setLastPage] = useState();
    const [perPage, setPerPage] = useState();
    const [total, setTotal] = useState();
    const [from, setFrom] = useState();
    const [to, setTo] = useState();

    const [loadingNext, setLoadingNext] = useState(false);
    const [loadingPrev, setLoadingPrev] = useState(false);
    const [loadingSearch, setLoadingSearch] = useState(false);
    
    const mapDefaults = {
        center: {
            lat: 33.614269, 
            lng: -85.834969
        },
        zoom: 7
    };

    useEffect(() => {
        // load stuff
        if (props && props.account) {
            // console.log('** LOAD ACCOUNT ID: ', props.accountId);
            setAccountId(props.accountId);
            setAccount(props.account);

            getLocation(props.accountId);
        }

        // loadAccount();
    }, []);

    const getLocation = (accountId) => {

        if (account && account.auto_geolocate) {
            if (!navigator.geolocation) {
                //   setStatus('Geolocation is not supported by your browser');
            } else {
            //   setStatus('Locating...');
                navigator.geolocation.getCurrentPosition((position) => {
                // setStatus(null);
                setUserLat(position.coords.latitude);
                setUserLng(position.coords.longitude);
                console.log(`User coords: ${position.coords.latitude}, ${position.coords.longitude}`);
                loadLocationsWithCoords(position.coords.latitude, position.coords.longitude);
                
                }, () => {
                // setStatus('Unable to retrieve your location');
                });
            }
        } else if (account) {
            loadLocations(accountId);
        }
      }


    const handleQueryChange = (event) => {
        setQueryString(event.target.value);
    };

    const handleSubmit = (event) => {
        // prevents the submit button from refreshing the page
        event.preventDefault();
        loadLocationsWithQuery(queryString);
        setLoadingSearch(true);
    };

    const handleResponse = (response) => {
        setLocations(response.data);
        setCurrentPage(response.current_page);
        setPerPage(response.perPage);
        setTotal(response.total);
        setFrom(response.from);
        setTo(response.to);
        setLastPage(response.last_page);
    }

    const loadLocationsWithCoords = (lat, lng, page = 1) => {
        setRequestInProgress(true);
        console.log(`${BASE_URL}/api/consumer/${props.accountId}/locations/nearest?lat=${lat}&lng=${lng}&page=${page}`);
        fetch(`${BASE_URL}/api/consumer/${props.accountId}/locations/nearest?lat=${lat}&lng=${lng}&page=${page}`)
            .then(res => res.json())
            .then(
                (result) => {
                    setRequestInProgress(false);
                    setLoadingNext(false);
                    setLoadingPrev(false);
                    setLoadingSearch(false);
                    if (result.data) {
                        handleResponse(result);
                    }
                    if (result.data.length > 0) {
                        setSelectedLocation(result.data[0]);
                        hoverLocation(result.data[0]);
                    }
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                }
            )
    }

    const loadLocationsWithQuery = (query, page = 1) => {
        setRequestInProgress(true);
        fetch(`${BASE_URL}/api/consumer/${accountId}/locations/nearest?query=${query}&page=${page}`)
            .then(res => res.json())
            .then(
                (result) => {
                    setRequestInProgress(false);
                    setLoadingNext(false);
                    setLoadingPrev(false);
                    setLoadingSearch(false);
                    if (result.data) {
                        handleResponse(result);
                    }

                    if (result.data.length > 0) {
                        setSelectedLocation(result.data[0]);
                        hoverLocation(result.data[0]);
                    }
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
                }
            )
    }

    const loadLocations = (accountId, page = 1) => {
        setRequestInProgress(true);
        console.log(`loadLocations: ${BASE_URL}/api/consumer/${accountId}/locations/nearest?page=${page}`);
        fetch(`${BASE_URL}/api/consumer/${accountId}/locations/nearest?page=${page}`)
            .then(res => res.json())
            .then(
                (result) => {
                    setLoadingNext(false);
                    setLoadingPrev(false);
                    setLoadingSearch(false);
                    if (result.data) {
                        handleResponse(result);
                        setRequestInProgress(false);
                    }
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
                }
            )
    };

    const hoverLocation = (location) => {
        timer = setTimeout(() => {
            if (map) {
                map.setZoom(7);
                setTimeout(() => {
                    map.panTo({lat: location.lat, lng: location.lng});
                    setTimeout(() => {
                        map.setZoom(11);
                    }, 750);
                }, 750);       
            }
        }, 500);
    }

    const leaveLocation = (location) => {
        clearTimeout(timer);
    }

    const handleApiLoaded = (map, maps) => {
        setMap(map);
    }

    const goToNextPage = () => {
        console.log('Go to next page...', currentPage + 1);

        setLoadingNext(true);

        if (userLat && userLng) {
            loadLocationsWithCoords(userLat, userLng, currentPage + 1);
        } else if (queryString) {
            loadLocationsWithQuery(queryString, currentPage + 1);
        } else {
            loadLocations(accountId, currentPage + 1);
        }
    }

    const goToPreviousPage = () => {
        console.log('Go to previous page...');

        setLoadingPrev(true);

        if (userLat && userLng) {
            loadLocationsWithCoords(userLat, userLng, currentPage - 1);
        } else if (queryString) {
            loadLocationsWithQuery(queryString, currentPage - 1);
        } else {
            loadLocations(accountId, currentPage - 1)
        }
    }

    const closeMarker = () => {
        setSelectedLocation(null);
    }

    return (
        <Container fluid>
            <Row className="justify-content-md-center">
                <Col md="8" className="padding-0 order-md-2 order-sm-1" style={{minHeight: '400px'}}>
                    <GoogleMapReact
                        // ref={(ref) => {
                        //     this.mapRef = ref;
                        // }}
                        bootstrapURLKeys={{ key: 'AIzaSyC7BhJXnGGR0K2MQjyoCci_WXGMPL_YjSY' }}
                        defaultCenter={mapDefaults.center}
                        center={mapDefaults.center}
                        defaultZoom={mapDefaults.zoom}
                        yesIWantToUseGoogleMapApiInternals
                        options={{ mapId:'a0d6c628297bb187' }}
                        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                        >
                            {locations.map( l => <Marker key={`map-${l.id}`} location={l} lat={l.lat} lng={l.lng} text={l.name} color={props.account.theme_color} onClick={() => { if (selectedLocation && selectedLocation.id !== l.id) { setSelectedLocation(l) } }} closeMarker={()=>{closeMarker()}} isSelected={selectedLocation && l.id === selectedLocation.id} />)}
                        </GoogleMapReact>
                </Col>

                <Col md="4" className="padding-0 order-md-1 order-sm-2">
                    <Row className="g-0" style={{backgroundColor: props.account.theme_color, color: '#fff', padding: '10px'}}>
                        <Form onSubmit={handleSubmit}>
                            <InputGroup>
                                <Form.Control type="text" placeholder="Enter city name or postal code" onChange={handleQueryChange} style={{borderRadius: 0, height: '45px', border: 'none', borderTopLeftRadius: '0.25rem', borderBottomLeftRadius: '0.25rem'}} />
                                <Button style={{width: '60px', height: '45px'}} variant="light" type="submit" className="btn-find" disabled={loadingSearch}>
                                    { loadingSearch ? (<><Loader style={{paddingTop: '2px'}} borderWidth={3} size={22} color='#ffffff' /></>) : 'Find' }
                                </Button>
                            </InputGroup>
                        </Form>
                    </Row>
                    <Row className="g-0" >
                        <Row className="list d-flex g-0">
                            <ul>
                                {locations.length < 1 ? (<div style={{height: '100%', alignSelf: 'center', flex: 1, alignItems: 'center', justifyContent: 'center', fontWeight: 'bold', color: '#d0d0d0', fontSize: '22px', alignContent: 'center'}}><div style={{alignSelf: 'center', paddingTop: '30px'}}>Search to find a location near you</div></div>) : (<div/>)}
                            {
                                locations.map(l => {
                                    return (
                                        // <li key={`loc-${l.id}`} onMouseEnter={() => hoverLocation(l)} onMouseLeave={() => leaveLocation(l)}>
                                        <li key={`loc-${l.id}`} className={`location-row ${ selectedLocation ? (l.id === selectedLocation.id ? 'active' : '') : ('') }`}>
                                            <Row className={`g-0 align-items-center`}  onClick={ () => { hoverLocation(l); setSelectedLocation(l) }}>
                                                { 
                                                    l.distance ? (
                                                        <Col sm={2} style={{flex: 0.2, textAlign: 'center'}}>
                                                            <Col>
                                                                <Row className="justify-content-center location-dot-solid">
                                                                    &nbsp;
                                                                </Row>
                                                            </Col>
                                                            <Col className="flex-d justify-content-center">
                                                                <Row className="justify-content-center" style={{fontSize: '22px'}} >
                                                                    <strong>{Math.round(l.distance * 10) / 10}</strong>
                                                                </Row>
                                                                <Row className="justify-content-center" style={{fontSize: '14px', color: '#8d8d8d'}}>
                                                                    <small>miles</small>
                                                                </Row>
                                                            </Col>
                                                        </Col>
                                                    ) : (<></>)
                                                }
                                                <Col sm={l.distance ? 10 : 12} style={{flex: 0.8, overflow: 'hidden', display: 'block', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                                                    <Row style={{fontSize: '17px', whiteSpace: 'nowrap', display: 'block', textOverflow: 'ellipsis'}}>
                                                        <span style={{whiteSpace: 'nowrap', display: 'block', textOverflow: 'ellipsis'}}><strong>{l.name}</strong></span>
                                                    </Row>
                                                    <Row style={{fontSize: '14px', color: '#888'}}>
                                                        <span>{l.address}</span>
                                                        <span>{l.city}, {l.state} {l.zip}</span>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </li>
                                    )
                                })
                            }
                            </ul>
                        </Row>
                    </Row>
                    <Row className="g-0 d-flex align-items-center padding-0">
                        { locations.length > 0 ? 
                            (<Row xs={12} className="g-0 align-items-center" style={{backgroundColor: props.account.theme_color, color: '#fff', padding: '10px'}}>
                                <Col xs={3}>
                                    { currentPage != 1 ? 
                                        (<Button style={{width: '43px', height: '41px'}} disabled={loadingPrev} onClick={() => { goToPreviousPage()}} style={{backgroundColor: 'transparent', borderColor: '#fff', fontWeight: 800, fontSize: 18}}>{ loadingPrev ? (<Loader borderWidth={2} size={20} color='#ffffff' style={{alignSelf: 'center', paddingTop: '2px'}} />) : (<>&larr;</>) }</Button>) :
                                        (<div />) 
                                    }
                                </Col>
                                <Col xs={6} style={{fontSize: '13px'}}>
                                    <span>Showing <strong>{from}-{to}</strong> of <strong>{total}</strong></span>
                                </Col>
                                <Col xs={3}>
                                    { lastPage != currentPage ? 
                                        (<Button style={{width: '43px', height: '41px'}} disabled={loadingNext} onClick={() => {goToNextPage()}} style={{backgroundColor: 'transparent', borderColor: '#fff', fontWeight: 800, fontSize: 18}}>{ loadingNext ? (<Loader borderWidth={2} size={20} color='#ffffff' style={{alignSelf: 'center', paddingTop: '2px'}} />) : (<>&rarr;</>) }</Button>) :
                                        (<div/>)
                                    }
                                </Col>
                            </Row>) : (<div/>) 
                        }
                        <Row className="g-0 align-items-center brand-footer" style={{backgroundColor: '#efefef', color: '#989898', fontSize: 12}}>
                            <a href="https://stockbird.io" target="_blank">
                                <Row>
                                    <span><small>powered by</small></span>
                                </Row>
                                <Row>
                                    <div className="align-items-center">
                                        <div className="logo d-inline-flex justify-content-center"></div>
                                    </div>
                                </Row>
                            </a>
                        </Row>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export const Marker = (props) => {
    const close = () => {
        props.closeMarker();
    }

    return (
        <div onClick={() => { props.onClick() }}>
            <div className="beacon" css={{
                backgroundColor: `${props.color}`,
                '&:before': {
                    boxShadow: `0px 0px 2px 2px ${props.color}`,
                }}}>
            </div>
            { props.isSelected ? (
                        <div className="detail-marker">
                            <div className="top">
                                <div className="icon" style={{backgroundColor: props.color}}></div>
                                <h5>{props.location.name}</h5>
                                <a href="#" className="close" onClick={() => { close() }} aria-label="Close Box">×</a>
                                {props.location.address}{ (props.location.address2 && props.location.address2.length > 0) ? (
                                    <p>{props.location.address2}</p>) : (<br />) }
                                {props.location.city}, {props.location.state} {props.location.zip}<br/><br/>
                                <a href={`https://www.google.com/maps/dir/?api=1&destination=${props.location.lat},${props.location.lng}`} target="_blank">Directions</a>
                                

                                <i></i>
                            </div>
                        </div>
                    ) : (<div />)
                    }
        </div>);
}