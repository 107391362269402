import { navigate } from "@reach/router";
import { useEffect, useState } from "react";
import { BASE_URL } from "./Constants";
import { Embed } from './Embed';
import { Loading } from './Loading';

export const LoadContainer = (props) => {
    const [accountId, setAccountId] = useState();
    const [error, setError] = useState();
    const [account, setAccount] = useState();
    // const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (props && props.accountId && props.accountId.length > 0) {
            setAccountId(props.accountId);
            loadAccount(props.accountId);
        }
    }, [props]);

    const loadAccount = (accountId) => {
        console.log('loading account...', accountId);
        // setIsLoading(true);
        fetch(`${BASE_URL}/api/consumer/${accountId}`)
            .then((res) => res.json()).then((res) => {
                console.log(res);
                setAccount(res);
            });
    }

    return (
        <div>
        { account ?
            (
                <Embed accountId={accountId} account={account} />    
            ) : (
                <Loading />
            )
        }
        </div>
    );
}