import logo from './logo.svg';
import './App.css';
import { Embed } from './Embed';
import { Router } from '@reach/router';
import { Loading } from './Loading';
import { LoadContainer } from './LoadContainer';
import { useEffect, useState } from 'react';

function App() {

  const [accountId, setAccountId] = useState('');

  useEffect(() => {
    const reactRoot = document.getElementById('stockbird-root');
    const accountId = reactRoot.getAttribute('data-account-id');
    console.log('** accountId: ', accountId);
    setAccountId(accountId);
  }, []);

  return (
    <div className="App">
      {/* <Router> */}
        <LoadContainer /*path="/:accountId"*/ accountId={accountId} />
      {/* </Router> */}
    </div>
  );
}

export default App;
